<template>
  <component :is="receiverData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="receiverData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('AppReceiver.errorFetchingUserData') }}
      </h4>
      <div class="alert-body">
        {{ $t('AppReceiver.noUserFound') }}
        <b-link
          class="alert-link"
          :to="{ name: 'apps-receivers-list'}"
        >
          {{ $t('AppReceiver.userList') }}
        </b-link>
        {{ $t('AppReceiver.forOthersUsers') }}
      </div>
    </b-alert>

    <b-tabs
      v-if="receiverData"
      pills
    >

      <!-- Tab: Receiver -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('AppReceiver.Receiver') }}</span>
        </template>
        <receiver-edit-tab-Receiver
          :receiver-data="receiverData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import receiverStoreModule from '../receiverStoreModule'
import ReceiverEditTabReceiver from './ReceiverEditTabReceiver.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ReceiverEditTabReceiver,
  },
  setup() {
    const receiverData = ref(null)

    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-receiver'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, receiverStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-receiver/fetchReceiver', { destinatarioId: router.currentRoute.params.destinatarioId })
      .then(response => { receiverData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          receiverData.value = undefined
        }
      })

    return {
      receiverData,
    }
  },
}
</script>

<style>

</style>
