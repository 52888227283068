<template>
  <div>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Customer-->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('AppReceiver.columns.customer')"
              rules="required"
            >
              <b-form-group
                :label="$t('AppReceiver.columns.customer')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="destinatarioData.clienteId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="customers"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: Alias -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppReceiver.columns.nickname')"
              rules="required|max:10"
            >
              <b-form-group
                :label="$t('AppReceiver.columns.nickname')"
                label-for="alias"
              >
                <b-form-input
                  id="alias"
                  v-model="destinatarioData.alias"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: Nombre -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppReceiver.columns.name')"
              rules="required|max:150"
            >
              <b-form-group
                :label="$t('AppReceiver.columns.name')"
                label-for="nombre"
              >
                <b-form-input
                  id="nombre"
                  v-model="destinatarioData.nombre"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: Rfc -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppReceiver.columns.rfc')"
              rules="required|max:13|min:12"
            >
              <b-form-group
                :label="$t('AppReceiver.columns.rfc')"
                label-for="rfc"
              >
                <b-form-input
                  id="rfc"
                  v-model="destinatarioData.rfc"
                  :state="getValidationState(validationContext)"
                  onkeyup="this.value = this.value.toUpperCase();"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: Domicilio -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppReceiver.columns.address')"
              rules="required|max:250"
            >
              <b-form-group
                :label="$t('AppReceiver.columns.address')"
                label-for="domicilio"
              >
                <b-form-input
                  id="domicilio"
                  v-model="destinatarioData.domicilio"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Lists.saveChanges') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('Lists.reset') }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, email, min, max,
} from '@validations'
import { ref } from '@vue/composition-api'
import optionService from '@/services/option.service'
import receiverService from '@/services/receiver.service'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    receiverData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      min,
      max,
    }
  },
  setup(props) {
    /* Services */
    const {
      updateReceiver,
    } = receiverService()
    const destinatarioData = ref(JSON.parse(JSON.stringify(props.receiverData)))

    const resetdestinatarioData = () => {
      destinatarioData.value = JSON.parse(JSON.stringify(props.receiverData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetdestinatarioData)

    const onSubmit = () => {
      updateReceiver(destinatarioData.value)
        .then(() => {
          router.push({ name: 'apps-receivers-list' })
        })
    }

    const customers = ref([])
    const { fetchCustomers } = optionService()
    fetchCustomers(data => { customers.value = data })

    return {
      // Services
      updateReceiver,

      customers,
      onSubmit,

      // Validation
      refFormObserver,
      getValidationState,
      resetForm,

      destinatarioData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
