import axios from '@axios'
import common from '@/libs/common'

export default function receiverService() {
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  const fetchReceivers = (data, callback) => {
    axios
      .get(`${API_URL}/Destinatarios`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createReceiver = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Destinatarios`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const fetchReceiver = ({ idReceiver }, callback) => {
    axios
      .get(`${API_URL}/Destinatarios/${idReceiver}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateReceiver = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/Destinatarios/${data.destinatarioId}`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const deleteReceiver = data => new Promise((resolve, reject) => {
    axios
      .delete(`${API_URL}/Destinatarios/${data.destinatarioId}`)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })

  return {
    fetchReceivers,
    createReceiver,
    fetchReceiver,
    updateReceiver,
    deleteReceiver,
  }
}
